import type { RouteRecordRaw } from 'vue-router'
import { prefixRoutes, route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { receiveRoute } from '@m/cabinet/components/views/utils'
import Receive from '@m/cabinet/components/views/Receive.vue'
import { facilityServicesRetrieveResource } from '@/services/facility.service'
import { toHash } from '@m/cabinet/utils'
import { doctorAccessPermission, accessPermission } from '@/modules/user/permissions'
import { IS_DOCTOR } from '@/modules/user/accessTokens'

import {
  GENERAL_TAB_KEY,
  SPECIALIZATION_TAB_KEY,
  DOCUMENTS_TAB_KEY,
  PLACE_OF_EMPLOYMENT_TAB_KEY,
} from '@/modules/cabinet/modules/Doctor/const'

import { MEDIA_TAB_KEY, SCHEDULE_TAB_KEY } from '@m/cabinet/modules/Branch/const'
import { FACILITY_DOCTORS_VIEW_NAME } from '@m/cabinet/routes'

export const DOCTOR_BASE = 'cabinet:doctor'
export const EMPLOYEE_DOCTOR_BASE = 'cabinet:employee-doctor'
const p = (name: string) => DOCTOR_BASE + ':' + name
const pE = (name: string) => EMPLOYEE_DOCTOR_BASE + ':' + name
const s = (name: string) => name+'/'

export const DOCTOR_VIEW_NAME = p('view')
export const DOCTOR_NOT_ADDED_VIEW_NAME = p('not-added')

export const DOCTOR_GENERAL_VIEW_NAME = p(GENERAL_TAB_KEY)
export const DOCTOR_SPECIALIZATION_VIEW_NAME = p(SPECIALIZATION_TAB_KEY)
export const DOCTOR_DOCUMENTS_VIEW_NAME = p(DOCUMENTS_TAB_KEY)
export const DOCTOR_PLACE_OF_EMPLOYMENT_VIEW_NAME = p(PLACE_OF_EMPLOYMENT_TAB_KEY)

export const EMPLOYEE_DOCTOR_VIEW_NAME = pE('view')
export const EMPLOYEE_DOCTOR_NOT_ADDED_VIEW_NAME = pE('not-added')
export const EMPLOYEE_DOCTOR_GENERAL_VIEW_NAME = pE(GENERAL_TAB_KEY)
export const EMPLOYEE_DOCTOR_SPECIALIZATION_VIEW_NAME = pE(SPECIALIZATION_TAB_KEY)
export const EMPLOYEE_DOCTOR_DOCUMENTS_VIEW_NAME = pE(DOCUMENTS_TAB_KEY)
export const EMPLOYEE_DOCTOR_PLACE_OF_EMPLOYMENT_VIEW_NAME = pE(PLACE_OF_EMPLOYMENT_TAB_KEY)

const View = () => import('./views/View.vue')
const NotAdded = () => import('./views/NotAdded.vue')

const General = () => import('@m/cabinet/modules/Doctor/tabs/General.vue')
const Documents = () => import('@m/cabinet/modules/Doctor/tabs/Documents.vue')
const Specialization = () => import('@m/cabinet/modules/Doctor/tabs/Specialization.vue')
const PlaceOfEmployment = () => import('@m/cabinet/modules/Doctor/tabs/PlaceOfEmployment.vue')

const BASE_MODEL_NAME = 'doctor/'
const BASE_EMPLOYEE_MODEL_NAME = 'employee-doctor/'


const FACILITY_BACK_PARAMS = [{ key: 'id', from: 'facility', path: ['id'] }]
const FALLBACK_PARAMS = {
  fallbackRouteName: FACILITY_DOCTORS_VIEW_NAME,
  parameters: FACILITY_BACK_PARAMS,
}

export function createRoutes(options: Options, isEmployeeRoutes = false): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route(':id/', View, isEmployeeRoutes ? EMPLOYEE_DOCTOR_VIEW_NAME : DOCTOR_VIEW_NAME, {
      meta: {
        base: isEmployeeRoutes ? EMPLOYEE_DOCTOR_BASE : DOCTOR_BASE,
        title: () => $t('Doctor profile'),
        ...(isEmployeeRoutes ? FALLBACK_PARAMS : {}),
        ...accessPermission([IS_DOCTOR], options),
      },
      children: [
        route(s(GENERAL_TAB_KEY), General, isEmployeeRoutes ? EMPLOYEE_DOCTOR_GENERAL_VIEW_NAME : DOCTOR_GENERAL_VIEW_NAME, {
          meta: {
            base: isEmployeeRoutes ? EMPLOYEE_DOCTOR_BASE : DOCTOR_BASE,
            title: () => $t('Doctor general information'),
        ...(isEmployeeRoutes ? FALLBACK_PARAMS : {}),
          },
        }),
        route(s(SPECIALIZATION_TAB_KEY), Specialization, isEmployeeRoutes ? EMPLOYEE_DOCTOR_SPECIALIZATION_VIEW_NAME : DOCTOR_SPECIALIZATION_VIEW_NAME, {
          meta: {
            base: isEmployeeRoutes ? EMPLOYEE_DOCTOR_BASE : DOCTOR_BASE,
            title: () => $t('Doctor specialization'),
        ...(isEmployeeRoutes ? FALLBACK_PARAMS : {}),
          },
        }),
        route(s(DOCUMENTS_TAB_KEY), Documents, isEmployeeRoutes ? EMPLOYEE_DOCTOR_DOCUMENTS_VIEW_NAME : DOCTOR_DOCUMENTS_VIEW_NAME, {
          meta: {
            base: isEmployeeRoutes ? EMPLOYEE_DOCTOR_BASE : DOCTOR_BASE,
            title: () => $t('Doctor documents'),
        ...(isEmployeeRoutes ? FALLBACK_PARAMS : {}),
          },
        }),
        route(s(PLACE_OF_EMPLOYMENT_TAB_KEY), PlaceOfEmployment, isEmployeeRoutes ? EMPLOYEE_DOCTOR_PLACE_OF_EMPLOYMENT_VIEW_NAME : DOCTOR_PLACE_OF_EMPLOYMENT_VIEW_NAME, {
          meta: {
            base: isEmployeeRoutes ? EMPLOYEE_DOCTOR_BASE : DOCTOR_BASE,
            title: () => $t('Doctor place of employment'),
        ...(isEmployeeRoutes ? FALLBACK_PARAMS : {}),
          },
        }),
        
      ]
    }),
    route('add/', NotAdded, isEmployeeRoutes ? EMPLOYEE_DOCTOR_NOT_ADDED_VIEW_NAME : DOCTOR_NOT_ADDED_VIEW_NAME, {
      meta: {
        base: isEmployeeRoutes ? EMPLOYEE_DOCTOR_BASE : DOCTOR_BASE,
        title: () => $t('Add Doctor'),
        ...(isEmployeeRoutes ? FALLBACK_PARAMS : {}),
        
        ...accessPermission([IS_DOCTOR], options),
      },
    }),
  ]
}

export { BASE_MODEL_NAME, BASE_EMPLOYEE_MODEL_NAME }