import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch, useSlots } from 'vue'
import { likesToggleResource } from '@services/likes.service'
import { useLikesStore } from '@m/store/likes'
import { useModalOpener } from '@/composables/useModalOpener'
import { useSuccessToast } from '@/composables/useToast'
import useGlobal from '@/composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'Toggler',
  props: {
  itemId: {
    type: [String, Number],
  },
  isLiked: {
    type: Boolean,
  },
  type: {
    type: String,
  },
},
  emits: ["update"],
  setup(__props, { emit: __emit }) {

const { $t } = useGlobal()

const props = __props

const emit = __emit

const slots = useSlots() as any

const isLoad = ref(false)

const isLikedLocal = ref(props.isLiked)

const likesStore = useLikesStore()

watch(() => likesStore.lastActionInfo, (nval) => {
  if (props.itemId === nval.added) {
    isLikedLocal.value = true
  }

  if (props.itemId === nval.removed) {
    isLikedLocal.value = false
  }
}, { deep: true })

const showNotification = () => {
  if (isLikedLocal.value) {
    useSuccessToast($t('Added to favorites'))
  } else {
    useSuccessToast($t('Removed from favorites'))
  }
}

const openForbiddenModal = () => {
  const { open } = useModalOpener({
    component: 'AuthForbidden',
    classes: ['modal--size_3sm modal--with-decor'],
    title: $t('Only authorized users can add to favorites'),
  })
  open()
}

const toggle = () => {
  if (!window.IS_AUTH) {
    openForbiddenModal()

    return
  }

  if (isLoad.value) return

  isLoad.value = true

  const formdata = { id: props.itemId, type: props.type }

  likesToggleResource.execute({}, formdata).then(() => {
    isLikedLocal.value = !isLikedLocal.value

    if (isLikedLocal.value) {
      likesStore.setAddedId(props.itemId)
      likesStore.setRemovedId(null)
    } else {
      likesStore.setRemovedId(props.itemId)
      likesStore.setAddedId(null)
    }

    emit('update', isLikedLocal.value)
    showNotification()

    likesStore.getLikesData()
  }).finally(() => {
    isLoad.value = false
  })
}

const render = () => slots.default({
  toggle,
  isActive: isLikedLocal.value,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render, { ref: "content" }, null, 512))
}
}

})