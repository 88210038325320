import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, mergeProps as _mergeProps, createBlock as _createBlock, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.languages, (l) => {
      return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($props.innerComponent), _mergeProps({ ref_for: true }, _ctx.$attrs, {
        name: `${$setup.name}_${l.id}`,
        "onUpdate:modelValue": [$event => $setup.setValue($event, l.id), ($event: any) => (($setup.localForm[l.id]) = $event)],
        modelValue: $setup.localForm[l.id],
        languages: $setup.languages
      }), null, 16, ["name", "onUpdate:modelValue", "modelValue", "languages"])), [
        [_vShow, l.id == $props.language]
      ])
    }), 256)),
    _renderSlot(_ctx.$slots, "errors", { languages: $setup.languages }),
    _renderSlot(_ctx.$slots, "label")
  ]))
}