import { r, createSender, createUpdater, createRemover, baseResource as baseSimpleResource } from '@/plugins/resource/resource'
import { queryPostfix, DETAIL_URL, CREATE_URL } from '@/plugins/resource/api'

// Models
const MODEL = 'appointment'

export const createAppointmentResource = r(CREATE_URL, MODEL, createUpdater)
export const unconfirmAppointmentResource = r('not-confirmed', MODEL, createUpdater)
export const appointmentDetailsResource = r(DETAIL_URL, MODEL)
// export const updateAppointmentResource = r(DETAIL_URL, MODEL, createUpdater)
export const appointmentListResource = r(queryPostfix('/list/', ['filters', 'status']), MODEL)
export const appointmentFiltersListResource = r(queryPostfix('/filter/list/', ['filters', 'status']), MODEL)

// export const appointmentFiltersInitialResource = r(`/filters/initial/`, MODEL, createSender)
export const appointmentFormValuesResource = r('/form/initial/', MODEL, createSender)
export const appointmentFormValuesAvailableResource = r('/form/available/', MODEL,  createSender)

// export const appointmentFiltersMedicalServicesResource = r(queryPostfix(`${MODEL}/filters/medical/{medicalId}/services/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
// export const appointmentFiltersMedicalSpecializationsResource = r(queryPostfix(`${MODEL}/filters/medical/{medicalId}/specializations/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
// export const appointmentFiltersMedicalDoctorsResource = r(queryPostfix(`${MODEL}/filters/medical/{medicalId}/doctors/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
export const appointmentFiltersMedicalReceptionsResource = r(queryPostfix(`${MODEL}/filters/medical/{medicalId}/receptions/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
// export const appointmentFiltersMedicalBranchesResource = r(queryPostfix(`${MODEL}/filters/medical/{medicalId}/branches/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))

// export const appointmentFiltersDoctorBranchesResource = r(queryPostfix(`${MODEL}/filters/doctor/{doctorId}/branches/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
// export const appointmentFiltersDoctorServicesResource = r(queryPostfix(`${MODEL}/filters/doctor/{doctorId}/services/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
export const appointmentFiltersDoctorReceptionsResource = r(queryPostfix(`${MODEL}/filters/doctor/{doctorId}/receptions/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))
// export const appointmentFiltersDoctorSpecializationsResource = r(queryPostfix(`${MODEL}/filters/doctor/{doctorId}/specializations/`, ['specialization', 'service', 'branch', 'doctor', 'reception']))

export const appointmentCurrentResource = r(queryPostfix(`${MODEL}/current/`))
export const appointmentCancelResource = r(`/canceled/`, MODEL, createSender)
export const appointmentCancelByPatientResource = r(`/canceled-by-patient/`, MODEL, createSender)
export const appointmentDeleteResource = r(`/delete/{id}/`, MODEL, createRemover)
export const appointmentUpdateResource = r('/update/', MODEL, createUpdater)
export const appointmentConfirmedResource = r('/confirmed/', MODEL, createSender)
export const appointmentCompletedResource = r('/completed/', MODEL, createSender)
export const appointmentSetNotConfirmedResource = r('/not-confirmed/', MODEL, createUpdater)
export const appointmentRepeatResource = r('/repeat/', MODEL, createSender)

export const appointmentPriceResource = r('get-price/', '', createSender)
