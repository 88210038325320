import { pipe, partial } from 'ramda'

import { i18n } from '@/i18n'
import { CURRENT_LOCALE } from '@/const'

const { t: $t, d: $d, n: $n } = i18n.global

export interface WordsMap {
  // zero : string
  one: string
  // two : string
  few: string
  many: string
  // other: string
}

export function pluralization(
  wordsMap: WordsMap,
  count: string | number
): string {
  const pr = new Intl.PluralRules(window.language)
  const intlCount = pr.select(Number(count))

  return `${$n(Number(count))} ${
    wordsMap[intlCount as keyof WordsMap] || wordsMap.few
  }`
}

export const fieldPluralizationResolver = (key: string, map) =>
  pipe(key, partial(pluralization, [map]))

function transformDateTime(this: any, config: any, value: any) {
  if (value) {
    return $d(new Date(value), config, CURRENT_LOCALE)
  }
  return $t('-')
}
export const fieldDateResolver = (
  key: string,
  config = 'datetime'
): ((...arg: any[]) => any) => pipe(key, partial(transformDateTime, [config]))

function transformNumber(config, value) {
  return $n(Number(value), config)
}
export const fieldNumberResolver = (
  key: any,
  config = null
): ((...arg: any[]) => any) => pipe(key, partial(transformNumber, [config]))

export function simplePluralize(
  n: number | string,
  textForms: [string, string, string]
): string {
  const n1 = Number(n)
  const n2 = n1 % 10

  if (n1 > 10 && n1 < 20) return textForms[2]
  if (n2 > 1 && n2 < 5) return textForms[1]
  if (n2 === 1) return textForms[0]

  return textForms[2]
}
const DateTimeUnits: Record<string, number> = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
}

export const rtf = new Intl.RelativeTimeFormat(window.language || 'en', {
  numeric: 'auto',
})

/**
 * Returns a formatted string representing the relative time between two dates.
 *
 * @param d1 - The date to compare against.
 * @param d2 - The reference date to compare against. Defaults to the current date.
 * @returns A formatted string representing the relative time between `d1` and `d2`.
 */
export const getRelativeTime = (
  d1: number | Date,
  d2: number | Date = new Date(),
  dateTimeUnits = DateTimeUnits
): string => {
  const date1 = typeof d1 === 'number' ? new Date(d1) : d1
  const date2 = typeof d2 === 'number' ? new Date(d2) : d2
  const elapsed = date1.getTime() - date2.getTime()

  for (const u in dateTimeUnits) {
    if (Math.abs(elapsed) > dateTimeUnits[u] || u === 'second') {
      return rtf.format(
        Math.round(elapsed / dateTimeUnits[u]),
        u as Intl.RelativeTimeFormatUnit
      )
    }
  }

  return new Intl.DateTimeFormat(window.navigator.language, {
    timeStyle: 'medium',
    dateStyle: 'short',
  }).format(date1)
}

export function getCurrencySymbol(currencyCode = window.default_currency || 'UAH', locale = window.language) {
  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    })
    return formatter.formatToParts(0).find((part) => part.type === 'currency')
      .value
  } catch (e) {
    console.error(e)
    return e.message
  }
}
