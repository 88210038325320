import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, computed, nextTick } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FakeInput',
  props: {
  tag: {
    type: String,
    default: 'd-control-input',
  },
  label: String,
  model: [String, Number, Object, Array],
  valueFormatter: Function,
  showAfter: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ['input'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const show = ref(false);
const value = ref(props.model);

watch(() => props.model, (newVal) => {
  value.value = newVal;
});

const visibleinput = ref<HTMLElement | null>(null);

const focus = () => {
  if (props.disabled) return;
  show.value = true;
  nextTick(() => {
    const parent = visibleinput.value;
    if (parent) {
      const input = (parent as any).$children[0].$children[0].$children[0].$children[0].$children[0].$refs.input;
      if (input.focus) {
        input.focus();
        return;
      }
      input.$el.focus();
    }
  });
};

const blur = () => {
  console.log('test');
  show.value = false;
  console.log('blur', value.value);
  emit('input', value.value);
};

const __returned__ = { props, emit, show, value, visibleinput, focus, blur, ref, watch, computed, nextTick }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})