import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localization_city_element = _resolveComponent("localization-city-element")!
  const _component_address_input = _resolveComponent("address-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localization_city_element, {
        currentCity: $setup.city,
        onSave: _cache[0] || (_cache[0] = ($event: any) => ($setup.saveCity($event))),
        "without-btn": true
      }, null, 8, ["currentCity"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_address_input, {
        appearance: ["bordered"],
        country: { code2: $setup.country },
        value: $setup.autocomplete,
        rules: $props.rules,
        onCallback: $setup.setLocationFromAutocomplete
      }, null, 8, ["country", "value", "rules"]),
      _renderSlot(_ctx.$slots, "error")
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "g-cell g-cols g-cols--12" }, [
      _createElementVNode("div", {
        class: "g-map map-wrapper",
        id: "map"
      })
    ], -1))
  ]))
}