import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, toRefs } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextArea',
  props: {
  modelValue: {
    default: '',
  },
  value: {
    default: '',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const { modelValue } = toRefs(props)

const emit = __emit

const val = ref(modelValue.value)

watch(() => modelValue.value, (nval, oval) => {
  if (oval === nval) return
  val.value = nval
}, { immediate: true })


const setValue = e => {
  if (e.target.validity.badInput) return
  const v = e.target.value
  emit("update:modelValue", v)
}

const __returned__ = { props, modelValue, emit, val, setValue, ref, watch, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})