import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useLoadingState } from '@aspectus/vue-loading-state'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'Receive',
  props: {
    resource: {},
    routeKey: {},
    resourceKey: {}
  },
  emits: ["error", "result"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const createChecker = (callback, promise) => (value) => callback(value, promise)

const props = __props

const emit = __emit

const { load, loading } = useLoadingState()
const route = useRoute()
const router = useRouter()

const result = ref({})
let localPromise = null

update()

async function applyError(result, promise) {
  if (localPromise !== promise) {
    return
  }

  if (404 === result.status) {
    router.push({
      name: 'cabinet:not-found',
      // Match the path of your current page and keep the same url...
      params: { pathMatch: route.path.split('/').slice(1).slice(0, -1) },
      // ...and the same query and hash.
      query: route.query,
      hash: route.hash,
    })
    return
  }

  if (403 === result.status) {
    router.push({
      name: 'cabinet:no-access',
      // Match the path of your current page and keep the same url...
      params: { pathMatch: route.path.split('/').slice(1).slice(0, -1) },
      // ...and the same query and hash.
      query: route.query,
      hash: route.hash,
    })
    return
  }

  emit('error', result)
  throw result
}
function update() {
  receive({ [props.resourceKey]: route.params[props.routeKey] })
}
function change(data) {
  result.value = data
}

function applyResult(res, promise) {
  if (localPromise !== promise) {
    return
  }
  if (res.code) {
    result.value = res.data
    emit('result', result.value)
    return
  }
  result.value = res
  emit('result', result.value)
}

function receive(parameters) {
  if (localPromise && localPromise?.cancel) {
    localPromise.cancel()
  }

  const promise = props.resource.execute(parameters)
  localPromise = promise

  return load(
    promise
      .then(createChecker(applyResult, promise))
      .catch(createChecker(applyError, promise))
  )
}

watch(() => route.params[props.routeKey], update)
watch(() => route.name, update)

const __returned__ = { createChecker, props, emit, load, loading, route, router, result, get localPromise() { return localPromise }, set localPromise(v) { localPromise = v }, applyError, update, change, applyResult, receive, ref, watch, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get useLoadingState() { return useLoadingState } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})