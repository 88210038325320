export const GENERAL_TAB_KEY = 'general'
export const SERVICES_TAB_KEY = 'services'
export const DOCTORS_TAB_KEY = 'doctors'
export const MEDIA_TAB_KEY = 'media'
export const SCHEDULE_TAB_KEY = 'schedule'


export const NOT_CONFIRMED_SINGLE_BRANCH_TABS = [
  GENERAL_TAB_KEY,
  MEDIA_TAB_KEY,
  SCHEDULE_TAB_KEY,
]
export const CONFIRMED_SINGLE_BRANCH_TABS = [
  GENERAL_TAB_KEY,
  MEDIA_TAB_KEY,
  SCHEDULE_TAB_KEY,
  SERVICES_TAB_KEY,
  DOCTORS_TAB_KEY,
]

export enum ScheduleStatus {
  open = 'open',
  close = 'close',
}

export enum BranchJoinStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  STOPPED = 'stopped',
}
export enum BranchPublicationStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export const getBranchJoinStatuses= (t) => [
  {
    value: BranchJoinStatus.PENDING,
    label: t('Pending'),
    icon: 'i-status-check',
    color: '#FFA31A',
  },
  {
    value: BranchJoinStatus.ACCEPTED,
    label: t('Accepted'),
    icon: 'i-status-active',
    color: '#1ED1AC',
  },
  {
    value: BranchJoinStatus.REJECTED,
    label: t('Rejected'),
    icon: 'i-status-failed',
    color: '#E74C3C',
  },
  {
    value: BranchJoinStatus.STOPPED,
    label: t('Stopped'),
    icon: 'i-status-blocked',
    color: '#E74C3C',
  },
]

export const getBranchPublicationStatusesTitle = (t) => ({
  [BranchPublicationStatus.DRAFT]: t('Draft'),
  [BranchPublicationStatus.ACTIVE]: t('Active'),
  [BranchPublicationStatus.ARCHIVE]: t('Archive'),
})

export const getBranchPublicationStatuses= (t) => [
  {
    value: BranchPublicationStatus.DRAFT,
    label: getBranchPublicationStatusesTitle(t)[BranchPublicationStatus.DRAFT],
  },
  {
    value: BranchPublicationStatus.ACTIVE,
    label: getBranchPublicationStatusesTitle(t)[BranchPublicationStatus.ACTIVE],
  },
  {
    value: BranchPublicationStatus.ARCHIVE,
    label: getBranchPublicationStatusesTitle(t)[BranchPublicationStatus.ARCHIVE],
  },
]
