import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import { getVideoId } from '@utils/helpers'

interface Props {
  url: string,
  previewBase?: string,
  previewWebp?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'View',
  props: {
    url: {},
    previewBase: {},
    previewWebp: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props
const youtubePreview = ref('')

const setVideoPreview = () => {
  const videoConfig = getVideoId(props.url)

  youtubePreview.value = videoConfig.previewUrl
}

if (!props.previewBase) {
  setVideoPreview()
}

const __returned__ = { props, youtubePreview, setVideoPreview, ref, get getVideoId() { return getVideoId } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})