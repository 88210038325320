import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "inliner-wrapper" }
const _hoisted_2 = { class: "um-space_pt um-space_pt--3 um-space_mb um-space_mb--3" }
const _hoisted_3 = { class: "g-row__g-cell g-cols" }
const _hoisted_4 = { class: "g-row" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--6" }
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = { class: "g-row g-row--justify_start g-row--appearance_spaced" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--narrow" }
const _hoisted_9 = { class: "mo-link__text mo-link__text--light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_trash = _resolveComponent("i-trash")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (el, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `form${i}`,
          class: "g-row g-row--space_md g-row--align_center g-row--appearance_spaced"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.form_fields, ({ rules, name, label, component, classname, inputtype, cols, props, events, multiplelang, customRules, colspan }, field_index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["g-cell g-cols", colspan ? `ds-table__cell--size_${colspan}`: `ds-table__cell--size_sm` ]),
              key: `${$props.type_name}${name}_${el.order}`
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(multiplelang ? "block-lang-switcher" : "dummy-tag"), {
                key: `wrap${$props.type_name}${name}${el.order}`,
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("changeLang", $event)))
              }, {
                default: _withCtx(({ currentLang }) => [
                  _createElementVNode("div", null, [
                    (_openBlock(), _createBlock(_component_d_control_input, _mergeProps({
                      key: `field${$props.type_name}${name}${el.order}${currentLang}`,
                      component: component || "d-control-input",
                      rules: rules || customRules && customRules(i),
                      index: i,
                      "input-label": label,
                      type: inputtype || "text",
                      modelValue: el[multiplelang ? `${name}_${currentLang}` : name ],
                      "onUpdate:modelValue": [
                        ($event: any) => ((el[multiplelang ? `${name}_${currentLang}` : name ]) = $event),
                        _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("update", $setup.list)))
                      ],
                      data: el[multiplelang ? `${name}_${currentLang}` : name ],
                      id: `${$props.type_name}${name}${el.order}${field_index}${i}`,
                      ref_for: true,
                      ref: `${$props.type_name}${name}${el.order}${field_index}${i}`,
                      name: $setup.nameGenerator(name, i)
                    }, props, _toHandlers(events), { class: classname }), null, 16, ["component", "rules", "index", "input-label", "type", "modelValue", "onUpdate:modelValue", "data", "id", "name", "class"]))
                  ])
                ]),
                _: 2
              }, 1056))
            ], 2))
          }), 128)),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "rm-btn", {
              remove: () => $setup.remove(i),
              length: $setup.list.length
            }, () => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: "inliner-btn inliner-btn--red",
                    href: "",
                    title: _ctx.$t('Delete'),
                    onClick: _withModifiers(($event: any) => ($setup.remove(i)), ["prevent"])
                  }, [
                    _createVNode(_component_i_trash, { class: "inliner-btn__text" })
                  ], 8, _hoisted_6)
                ])
              ])
            ])
          ])
        ]))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "line-h line-h--light" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "add-btn", {
          addFields: () => $setup.addFields()
        }, () => [
          _createElementVNode("div", {
            class: "mo-link mo-link--styling_default mo-link--variant_start",
            href: "",
            onClick: _withModifiers($setup.addFields, ["prevent"])
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "mo-link__icon" }, "+", -1)),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('Add more')), 1)
          ])
        ])
      ])
    ])
  ]))
}