import { defineComponent as _defineComponent } from 'vue'
import qs from 'qs'
import { ref, reactive, computed, onMounted } from 'vue'
import {
  medicalFacilityFiltersResource,
} from '@/services/facility.service'
import { prepareAttributes } from '@utils/filters'

import SelectWidget from '@/components/filter/widgets/SelectWidget'
import DoctorReceptionWidget from '@/components/filter/widgets/DoctorReceptionWidget'


export default /*@__PURE__*/_defineComponent({
  __name: 'Facilities',
  props: {
  baseUrl: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const resetTrigger = ref(false)

const additionalFiltersIsVisible = ref(false)

const params = reactive({
  filters: {},
  pagination: {},
})

const filters = ref({
  base: [],
  additional: [],
})

const preselectFilters = items => {
  items.forEach(item => {
    if (item.type === 'switch') {
      params.filters[item.keyFilter] = item.default
    }
    if (item.type === 'reception') {
      params.filters[item.keyFilter] = item.values[0]?.value || null
    }
  })
}

const getFilters = async () => {
  const data = await medicalFacilityFiltersResource.execute({})

  preselectFilters(data.filters)

  filters.value.base = data.filters.filter(el => !el.isDynamic)

  const additionalFilters = data.filters.filter(el => el.isDynamic)
  const preparedAdditionalFilters = prepareAttributes(additionalFilters, 'select')

  filters.value.additional = preparedAdditionalFilters
}

const resetFilters = () => {
  resetTrigger.value = !resetTrigger.value

  params.filters = {}

  preselectFilters(filters.value.base)
}

const goToCatalog = () => {
  const preparedParams = JSON.parse(JSON.stringify(params.filters))

  if (preparedParams.location || switchWidgetIsHidden.value) {
    delete preparedParams.location
  }

  const filtersUrl = qs.stringify(preparedParams, {
    delimiter: '/',
    arrayFormat: 'comma',
    encode: false,
  })

  if (filtersUrl.length) {
    window.location = `${props.baseUrl}filters/${filtersUrl.replace(/=/g, ':')}/`
  } else {
    window.location = props.baseUrl
  }
}

const toggleAdditionalFilters = () => {
  additionalFiltersIsVisible.value = !additionalFiltersIsVisible.value
}

const baseFilters = computed(() => {
  return filters.value.base.filter(el => el.type === 'checkbox')
})

const switchFilter = computed(() => {
  return filters.value.base.find(el => el.type === 'switch')
})

const receptionFilter = computed(() => {
  return filters.value.base.find(el => el.type === 'reception')
})

const filtersList = computed(() => {
  const allFilters = additionalFiltersIsVisible.value ? [...baseFilters.value, ...filters.value.additional] : baseFilters.value

  const preparedFilters = allFilters.map(el => {
    if (['specializations'].includes(el.keyFilter)) {
      el.config.searchable = true
    }

    return el
  })

  return preparedFilters
})

const switchWidgetIsHidden = computed(() => {
  const val = params.filters[receptionFilter.value.keyFilter]
  const preparedVal = Array.isArray(val) ? val[0] : val

  if (preparedVal) {
    const option = receptionFilter.value.values.find(v => v.value === preparedVal)

    return !option.locationDepends
  }

  return true
})

onMounted(async () => {
  await getFilters()
})

const __returned__ = { props, resetTrigger, additionalFiltersIsVisible, params, filters, preselectFilters, getFilters, resetFilters, goToCatalog, toggleAdditionalFilters, baseFilters, switchFilter, receptionFilter, filtersList, switchWidgetIsHidden, get qs() { return qs }, ref, reactive, computed, onMounted, get medicalFacilityFiltersResource() { return medicalFacilityFiltersResource }, get prepareAttributes() { return prepareAttributes }, get SelectWidget() { return SelectWidget }, get DoctorReceptionWidget() { return DoctorReceptionWidget } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})