
import { App, defineAsyncComponent } from 'vue'

import ModalTrigger from './modals/Trigger.vue'
import UiSidebar from './Sidebar.vue'
import Tabs from './Tabs.vue'

const UiAccordion = defineAsyncComponent(() => import('./Accordion.vue'))
const AppIcon = defineAsyncComponent(() => import('./AppIcon.vue'))
const Timer = defineAsyncComponent(() => import('./Timer.vue'))
const ShareList = defineAsyncComponent(() => import('./ShareList.vue'))
const ShareModal = defineAsyncComponent(() => import('./ShareModal.vue'))
const Loader = defineAsyncComponent(() => import('./Loader.vue'))
const Lightbox = defineAsyncComponent(() => import('./Lightbox.vue'))
const Rating = defineAsyncComponent(() => import('./Rating.vue'))
const Dropdown = defineAsyncComponent(() => import('./Dropdown.vue'))
const Toggler = defineAsyncComponent(() => import('./Toggler'))
const ShowMoreTags = defineAsyncComponent(() => import('./ShowMoreTags.vue'))
const ScrollToTop = defineAsyncComponent(() => import('./ScrollToTop.vue'))
const UiEllipsisContent = defineAsyncComponent(() => import('./UiEllipsisContent.vue'))

const install = (app: App<Element>, prefix = 'Ui'): void => {
  app
    .component(`${prefix}Sidebar`, UiSidebar)
    .component(`${prefix}Accordion`, UiAccordion)
    .component(`AppIcon`, AppIcon)
    .component(`${prefix}ModalTrigger`, ModalTrigger)
    .component(`${prefix}Timer`, Timer)
    .component(`${prefix}ShareList`, ShareList)
    .component(`${prefix}ShareModal`, ShareModal)
    .component(`${prefix}Loader`, Loader)
    .component(`${prefix}Lightbox`, Lightbox)
    .component(`${prefix}SimpleTabs`, Tabs)
    .component(`${prefix}Rating`, Rating)
    .component(`${prefix}Dropdown`, Dropdown)
    .component(`${prefix}Toggler`, Toggler)
    .component(`${prefix}ShowMoreTags`, ShowMoreTags)
    .component(`${prefix}ScrollToTop`, ScrollToTop)
    .component(`${prefix}EllipsisContent`, UiEllipsisContent)
}

export default {
  install,
}
