import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue';
import { useConfirmDialog } from '@/composables/useConfirm'

const SPLITED_LENGTH = 1;


export default /*@__PURE__*/_defineComponent({
  __name: 'Inliner',
  props: {
  tableClasses: {},
  hasDnd: Boolean,
  user: {},
  type_name: String,
  base: Object,
  form_fields: {
    type: Array,
    default: () => [],
  },
  getResource: Function,
  postResource: Function,
  patchResource: Function,
  deleteResource: Function,
  prepare: Function,
  value: {
    default: () => [],
  },
  form: {
    type: Array,
    default: () => [],
  },
  indexedName: {
    type: Boolean,
    default: false,
  },
  hasInited: {
    type: Boolean,
    default: true,
  },
},
  emits: ['update', 'removed', 'changeLang'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const list = ref(props.form);
watch(() => props.form, () => {
  updateFields();
}, { deep: true, immediate: true });

onMounted(() => {
  if (!props.form.length && props.hasInited) addFields();
});

function updateFields() {
  list.value = props.form;
}

function nameGenerator(name: string, i: number) {
  if (props.indexedName) return `${name}_${i}`;
  return name;
}

async function remove(i: number) {
  const question = 'Вы действительно хотите удалить?';
  try {
    await useConfirmDialog({
      question,
    })
    if (list.value[i].id && props.deleteResource) {
      await props.deleteResource.execute({ id: list.value[i].id });
    }
    list.value.splice(i, SPLITED_LENGTH);
    emit('removed');
  } catch (e) {
    console.log(e);
  }
}

function addFields() {
  const clone = JSON.parse(JSON.stringify(props.base));
  clone.order = new Date().getTime();
  list.value.push(clone);
}

const __returned__ = { props, emit, list, SPLITED_LENGTH, updateFields, nameGenerator, remove, addFields, ref, watch, onMounted, get useConfirmDialog() { return useConfirmDialog } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})