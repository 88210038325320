import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-input", { error: $props.errors[$props.name] || !$setup.isValid, success: $props.isSuccess }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($props.multiple ? $setup.MultiSelect : $setup.SingleSelect), _mergeProps({
      class: ["d-control-input__element", { "is-multiple": $props.multiple }]
    }, _ctx.$attrs, {
      options: $props.options || [],
      label: $props.labelBy,
      "track-by": $props.trackBy,
      placeholder: $props.placeholder,
      disabled: $props.disabled,
      "allow-empty": $props.allowEmpty,
      modelValue: $setup.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      required: $props.rules,
      multiple: $props.multiple,
      "show-labels": false
    }), _createSlots({ _: 2 }, [
      _renderList($setup.eSlots, (name, key) => {
        return {
          name: name,
          fn: _withCtx((binded) => [
            _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
          ])
        }
      }),
      ($props.pagination && Math.ceil($props.pagination.total/$props.pagination.page_size) > $props.pagination.page)
        ? {
            name: "afterList",
            fn: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["class", "options", "label", "track-by", "placeholder", "disabled", "allow-empty", "modelValue", "required", "multiple"])),
    _renderSlot(_ctx.$slots, "label")
  ], 2))
}