import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageLabel',
  props: {
  modelValue: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  afterText: {
    type: String,
    default: ''
  },
  icon: {
    default: 'i-dropzone'
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const __returned__ = { props, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})