import { defineComponent as _defineComponent } from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ref, watch, toRefs } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Editor',
  props: {
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    default: '',
  },
  value: {
    default: '',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();


const props = __props

const ckeditorConfig = {
  // 'undo', 'redo', '|',
  toolbar: ['heading', '|', 'bold', 'italic', '|', 'link'],
}

const { modelValue } = toRefs(props)

const emit = __emit

const val = ref(modelValue.value)
const editor = ref(null)

watch(() => modelValue.value, (nval, oval) => {
  if (oval === nval) return
  // if (editor.value) {
  //   val.value = editor.value.setHTML(nval)
  // }
}, { immediate: true })

const setValue = e => {
  if (editor.value) {
    emit("update:modelValue", editor.value.getHTML())
  }
}


const __returned__ = { props, ckeditorConfig, modelValue, emit, val, editor, setValue, get ClassicEditor() { return ClassicEditor }, ref, watch, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})