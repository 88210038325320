import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DoctorReceptionWidget',
  props: {
  filter: Object,
  resetTrigger: Boolean,
  modelValue: {},
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const model = ref(props.modelValue || null)

const update = () => {
  emit('update:modelValue', model.value)
}

const select = item => {
  if (model.value === item.value) return

  model.value = item.value

  update()
}

const setValueFromUrl = (val: any) => {
  const items = props.filter.values

  if (!val) {
    model.value = null

    return
  }

  const checked = items.find(el => (val).toString() === el.value.toString())

  if (checked) {
    model.value = checked.value
  }
}

watch(() => props.modelValue, (nval) => {
  setValueFromUrl(nval)
}, { immediate: true, deep: true })

watch(() => props.resetTrigger, () => model.value = null)

const __returned__ = { props, emit, model, update, select, setValueFromUrl, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})