import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LabelComponent',
  props: {
  isRequired: Boolean,
  inputLabel: String,
  id: String,
  value: [Array, String, Boolean, Object],
  modelValue: {},
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const isValue = computed(() => {
  const { modelValue, value } = props
  let result = false
  if (modelValue || value) {
    result = check(modelValue || value)
  }
  return result
})

const check = val => {
  if ('boolean' === typeof val) {
    return val
  }
  if (Array.isArray(val)) {
    return Boolean(val.length)
  }

  if (val && val.length || val) {
    return true
  }

  return false
}


const __returned__ = { props, isValue, check, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})