import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "silentbox-overlay__embed" }
const _hoisted_2 = ["allow", "src"]
const _hoisted_3 = {
  key: 1,
  class: "silentbox-video__frame"
}
const _hoisted_4 = ["src", "autoplay", "controls"]
const _hoisted_5 = ["srcset", "src", "alt"]
const _hoisted_6 = {
  key: 1,
  id: "silentbox-overlay__tool-buttons"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 0,
  id: "silentbox-overlay__arrow-buttons"
}

import { isEmbedVideo, isLocalVideo } from '../utils/itemUtils'
import type { ItemProps, SilentBoxOptions } from '../types'
import {
  getVimeoVideoId,
  getYoutubeVideoId,
  getTwitchChannelId,
} from '../utils/videoUtils'
import { onUpdated, reactive, computed, inject } from 'vue'

export interface OverlayProps {
  item: ItemProps
  visible: boolean
  currentItem: number
  totalItems: number
}

// Inject plugin options, ensure the label is always defined.

export default /*@__PURE__*/_defineComponent({
  __name: 'SilentBoxOverlay',
  props: {
    item: {},
    visible: { type: Boolean },
    currentItem: {},
    totalItems: {}
  },
  emits: [
  'silentbox-internal-close-overlay',
  'silentbox-internal-get-next-item',
  'silentbox-internal-get-prev-item',
  // Following events are emitted only when component is opened from global call
  'silentbox-overlay-opened',
  'silentbox-overlay-hidden',
],
  setup(__props: any, { emit: __emit }) {

const silentBoxOptions = inject<SilentBoxOptions>('silent-box-options') || {
  downloadButtonLabel: 'Download',
}

const props = __props

/**
 * Compute download link property.
 * @return {string}
 */
const downloadLink = computed<string>(() => {
  if (typeof props.item.download === 'string') {
    return props.item.download
  }
  return props.item.src
})

/**
 * Get YouTube video embed URL and set autoplay and controls state.
 *
 * @param {string} url any video URL from YouTube
 * @return string
 */
const getYouTubeVideoURL = (url: string): string => {
  let videoURL = ''
  const videoId = getYoutubeVideoId(url)
  if (videoId) {
    videoURL = `${location.protocol}//www.youtube.com/embed/${videoId}?rel=0`
    if (props.item.autoplay) videoURL += '&autoplay=1'
    // check whether controls should disabled as default is enabled
    if (!props.item.controls) videoURL += '&controls=0'
  }
  return videoURL
}
/**
 * Get Vimeo video embed URL and set autoplay and controls state.
 *
 * @param {string} url any video URL from Vimeo or VimeoPro
 * @return string
 */
const getVimeoVideoURL = (url: string): string => {
  let videoURL = ''
  const videoId = getVimeoVideoId(url)
  if (videoId) {
    videoURL = `${location.protocol}//player.vimeo.com/video/${videoId}?rel=0`
    if (props.item.autoplay) videoURL += '&autoplay=1'
  }
  return videoURL
}
/**
 * Get Twitch video embed URL.
 */
const getTwitchVideURL = (url: string): string => {
  let videoURL = ''
  const channelID = getTwitchChannelId(url)
  if (channelID) {
    videoURL = `${location.protocol}//player.twitch.tv/?channel=${channelID}&parent=${location.hostname}`
    if (props.item.autoplay) videoURL += '&autoplay=true'
  }
  return videoURL
}
/**
 * Check whether provided URL is YouTube or Vimeo, otherwise always return it
 * as it is.
 *
 * @param {string} url
 * @return {string}
 */
const getVideoURL = (url: string): string => {
  if (/(youtu\.?be)/.test(url)) {
    return getYouTubeVideoURL(url)
  } else if (/(vimeo(pro)?\.com)/.test(url)) {
    return getVimeoVideoURL(url)
  } else if (/(?:player\.|clips\.|www\.)?twitch\.tv/.test(url)) {
    return getTwitchVideURL(url)
  }
  return url
}
/**
 * Lock horizontal and vertical scrolling when user opens overlay to improve
 * user-experience.
 */
const lockScrolling = (): void => {
  if (!document.body.classList.contains('silentbox-is-opened')) {
    document.body.classList.add('silentbox-is-opened')
  }
}
/**
 * Unlock horizontal and vertical scrolling.
 */
const unlockScrolling = (): void => {
  if (document.body.classList.contains('silentbox-is-opened')) {
    document.body.classList.remove('silentbox-is-opened')
  }
}

const animation = reactive({
  name: 'silentbox-animation__swipe-left',
})

const emit = __emit
const handleClose = (): void => {
  emit('silentbox-internal-close-overlay')
}
const handleMoveNext = (): void => {
  animation.name = 'silentbox-animation__swipe-left'
  emit('silentbox-internal-get-next-item')
}
const handleMovePrev = (): void => {
  animation.name = 'silentbox-animation__swipe-right'
  emit('silentbox-internal-get-prev-item')
}

const touchPosition = reactive({
  posX: 0,
  posY: 0,
})
/**
 * Save user's start position on touch-enabled device and touch event began.
 *
 * @param {TouchEvent} event
 */
const touchStart = (event: TouchEvent): void => {
  const { clientX: x, clientY: y } = event.touches[0]
  touchPosition.posX = x
  touchPosition.posY = y
}
/**
 * Calculate user's touch movement direction, so we can change overlay content
 * accordingly.
 *
 * @param {TouchEvent} event
 */
const handleTouchMove = (event: TouchEvent): void => {
  const { clientX: x, clientY: y } = event.touches[0]
  const { posX, posY } = touchPosition

  if (posX === 0 || posY === 0) {
    // return if user did not moved
    return
  }
  const xDiff = posX - x
  const yDiff = posY - y
  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    // User moved on x-axis
    if (xDiff > 0) {
      // if difference between starging point
      // and movement point is positive,
      // move to next item
      handleMoveNext()
    } else {
      // otherwise move to previous item
      handleMovePrev()
    }
    // ... y-axis events could be added here
  }

  // Reset start position for next event
  touchPosition.posX = 0
  touchPosition.posY = 0
}
/**
 * Register keyboard interaction.
 *
 * @param {KeyboardEvent} event
 */
const handleKeyInteraction = (event: KeyboardEvent): void => {
  if (event.code === 'Escape') handleClose()
  if (event.code === 'ArrowRight') handleMoveNext()
  if (event.code === 'ArrowLeft') handleMovePrev()
}
onUpdated(() => {
  // We must use onUpdate hook as otherwise it seems Vue adds event listener
  // for each instance of silent-box. Other hooks as onBeforeUnmount or
  // onUnmount does not remove event listner, thus this awkward condition.
  if (props.visible) {
    window.addEventListener('keyup', handleKeyInteraction)
    lockScrolling()
  } else {
    window.removeEventListener('keyup', handleKeyInteraction)
    unlockScrolling()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (props.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "silentbox-overlay",
          role: "overlay",
          onTouchstart: touchStart,
          onTouchmove: handleTouchMove
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { id: "silentbox-overlay__background" }, null, -1)),
          _createVNode(_Transition, {
            name: animation.name,
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("div", {
                id: "silentbox-overlay__content",
                onClick: _withModifiers(handleClose, ["stop"]),
                key: props.item.src
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", {
                    id: "silentbox-overlay__container",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                  }, [
                    (_unref(isEmbedVideo)(props.item.src))
                      ? (_openBlock(), _createElementBlock("iframe", {
                          key: 0,
                          allow: `accelerometer; ${
                  !!props.item.autoplay && 'autoplay;'
                } encrypted-media; gyroscope; picture-in-picture`,
                          src: getVideoURL(props.item.src),
                          frameborder: "0",
                          width: "100%",
                          height: "100%",
                          allowfullscreen: ""
                        }, null, 8, _hoisted_2))
                      : (_unref(isLocalVideo)(props.item.src))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("video", {
                              src: props.item.src,
                              autoplay: props.item.autoplay ? true : false,
                              controls: props.item.controls,
                              class: "silentbox-video__embed"
                            }, null, 8, _hoisted_4)
                          ]))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            srcset: 
                  props.item.srcSet
                    ? props.item.srcSet.join(',')
                    : props.item.src
                ,
                            src: props.item.src,
                            alt: props.item.alt
                          }, null, 8, _hoisted_5))
                  ]),
                  (props.item.description)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        id: "silentbox-overlay__description",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
                      }, _toDisplayString(props.item.description), 1))
                    : _createCommentVNode("", true),
                  (props.item.download)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("a", {
                          href: downloadLink.value,
                          class: "download",
                          download: ""
                        }, _toDisplayString(_unref(silentBoxOptions).downloadButtonLabel), 9, _hoisted_7)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            ]),
            _: 1
          }, 8, ["name"]),
          _createElementVNode("button", {
            id: "silentbox-overlay__close-button",
            role: "button",
            type: "button",
            tabindex: "3",
            onClick: _withModifiers(handleClose, ["prevent"]),
            onKeyup: _withKeys(handleClose, ["enter"])
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "icon" }, null, -1)
          ]), 32),
          (props.totalItems > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("button", {
                  class: "silentbox-overlay__arrow-button silentbox-overlay__arrow-button--prev",
                  role: "button",
                  type: "button",
                  tabindex: "2",
                  onClick: _withModifiers(handleMovePrev, ["stop"]),
                  onKeyup: _withKeys(handleMovePrev, ["enter"])
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "arrow arrow-previous" }, null, -1)
                ]), 32),
                _createElementVNode("button", {
                  class: "silentbox-overlay__arrow-button silentbox-overlay__arrow-button--next",
                  role: "button",
                  type: "button",
                  tabindex: "1",
                  onClick: _withModifiers(handleMoveNext, ["stop"]),
                  onKeyup: _withKeys(handleMoveNext, ["enter"])
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "arrow arrow-next" }, null, -1)
                ]), 32)
              ]))
            : _createCommentVNode("", true)
        ], 32))
      : _createCommentVNode("", true)
  ]))
}
}

})