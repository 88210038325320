import { defineComponent as _defineComponent } from 'vue'
import { ref, toRefs, watch } from 'vue'

interface Props {
  name: string,
  modelValue: boolean,
  disabled?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Switch',
  props: {
    name: {},
    modelValue: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const props = __props

const { modelValue } = toRefs(props)

const updatedValue = ref<boolean>(false)

const check = (checked: boolean): void => {
  updatedValue.value = checked

  emit('update:modelValue', updatedValue.value)
}

const setValueFromUrl = (checked: boolean): void => {
  updatedValue.value = checked
}

watch(() => modelValue.value, () => {
  setValueFromUrl(modelValue.value)
}, { immediate: true })


const __returned__ = { emit, props, modelValue, updatedValue, check, setValueFromUrl, ref, toRefs, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})