import { defineComponent as _defineComponent } from 'vue'
import VueMultiselect from "@/packages/wc-multiselect"
import { ref, watch, useSlots, computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash'

const r = 'required'


export default /*@__PURE__*/_defineComponent({
  __name: 'MutiselectInput',
  props: {
  errors: {
    type: Object,
    default: () => ({}),
  },
  disabled: Boolean,
  isSuccess: Boolean,
  inputLabel: String,
  staticLabel: Boolean,
  searchable: Boolean,
  options: Array,
  labelBy: String,
  customLabel: {
    type: Function,
    default: (option, label) => {
      if (isEmpty(option)) return ''
      return label ? option[label] : option
    }
  },
  trackBy: {
    type: String,
    default: 'id',
  },
  allowEmpty: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  pagination: Object,
  showObserveVisibility: Boolean,
  modelValue: Object,
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: String,
    default: '',
  },
  setValue: {},
  name: {},
  multiple: {},
  inputClass: String,
},
  emits: ['afterList', 'clearMultiselect', 'update:modelValue',],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const { t } = useI18n()

const noOptionsText = t("List is empty.")
const noResultText = t("No elements found. Consider changing the search query.")

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

const eSlots = computed(() => {
  return getESlots([...Object.keys(useSlots())])
})


const props = __props

const model = ref(props.modelValue)

watch(() => props.modelValue, () => {
  model.value = props.modelValue
  // if (isEmpty(props.modelValue)) {
  //   return
  // }
  // console.log(props.modelValue, 'props.modelValue');
  // if (Array.isArray(props.modelValue)) {
  //   model.value = props.modelValue.map(e => ({ ...e, selected: true }))
  //   return
  // }
  // model.value = {...props.modelValue, selected: true }

})

// const localOptions = computed(() => {
//   type Option = string | number | Record<string, any>
//   return props.options.reduce((acc: Option[], option: Option) => {
//     if (isEmpty(props.modelValue)) {
//       acc.push({...option, selected: false })
//       return acc
//     }
//     if (Array.isArray(props.modelValue)) {
//       acc.push({...option, selected: !!props.modelValue.find(r => option[props.trackBy] == r[props.trackBy]) })
//       return acc
//     }
//     acc.push({...option, selected: obj[props.trackBy] === props.modelValue[props.trackBy]})
//     return acc
//   }, [] as any[])
//   // return props.options.map(option => ({...option, selected: false }))

// })

function checkSelected(option) {

  if (Array.isArray(props.modelValue)) {
    return  !!props.modelValue.find(r => option[props.trackBy] == r[props.trackBy])
  }
  return props.trackBy ? option[props.trackBy] === props.modelValue?.[props.trackBy] : option === props.modelValue
}

const isRequired = props.rules.includes(r)
const isValid = ref(null)

const emit = __emit

async function visibilityChanged([{ isIntersecting }]) {
  if(isIntersecting) {
    await emit('afterList', isIntersecting)
  }
}

const getOptionLabel = (option) => {
  if (isEmpty(option)) return ''
  if (option.isTag) return option.label
  if (option.$isLabel) return option.$groupLabel
  
  const label = props.customLabel(option, props.labelBy)
  if (isEmpty(label)) return ''
  return label
}


// const select = option => {
//   console.log(option, 'option');
//   option.selected = !option.selected
//   // emit(update:modelValue)
//   // console.log(option.selected, 'selected');
//   if (!option.selected) {
//     nextTick(() => {
//       if (Array.isArray(model.value)) {
//         if (model.value.length == 1) {
//           model.value = []
//           emit('update:modelValue', [])
//           return
//         }
//         model.value = model.value.filter(r => r.selected)
//         emit('update:modelValue', [])
//         return
//       }
  
//       model.value = ''
//       emit('update:modelValue', '')
//     })
//   }
//   console.log(option);
// }

const ts = r2 => {
  // emit('update:modelValue', model.value)
  r2()
}

const check = (value, id) => {
  emit('update:modelValue', value)
  
}


const __returned__ = { eSlotsResolver, t, noOptionsText, noResultText, getESlots, eSlots, props, r, model, checkSelected, isRequired, isValid, emit, visibilityChanged, getOptionLabel, ts, check, get VueMultiselect() { return VueMultiselect }, ref, watch, useSlots, computed, nextTick, get useI18n() { return useI18n }, get isEmpty() { return isEmpty } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})