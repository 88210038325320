import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mo-switch" }
const _hoisted_2 = { class: "mo-switch__content" }
const _hoisted_3 = ["checked", "name", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("input", {
        class: "mo-switch__element",
        type: "checkbox",
        checked: $setup.updatedValue,
        name: $props.name,
        onInput: _cache[0] || (_cache[0] = (event) => $setup.check(event.target.checked)),
        disabled: $props.disabled
      }, null, 40, _hoisted_3),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mo-switch__slider" }, null, -1))
    ])
  ]))
}