import { defineComponent as _defineComponent } from 'vue'
import { type ComputedRef, computed, ref, watch, toRefs, useAttrs } from 'vue'
import { path } from 'ramda'
import { useUserStore } from '@m/user/store'
import { equals } from 'ramda'


export default /*@__PURE__*/_defineComponent({
  __name: 'MultilangInput',
  props: {
    value: {},
    language: {},
    innerComponent: {},
    name: {},
    form: {},
    onInput: {},
    "onUpdate:isValid": {},
    "onUpdate:modelValue": {},
    "onUpdate:model": {}
  },
  emits: ['update:modelValue', 'setRules'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const attrs = useAttrs()

const props = __props

const emit = __emit

const store = useUserStore()

const languages: ComputedRef<Record<string, string>[]> = computed(() => store?.country?.languagesAvailable || [])
const defaultLanguage: ComputedRef<string> = computed(() => store.defaultLanguage)

const { name, form } = toRefs(props)

const localForm = ref(languages.value.reduce((acc, o) => {
  acc[o.id] = ''
  return acc
}, {}))

const setValue = (value, lang) => {
  localForm.value[lang] = value
  emit('update:modelValue', localForm.value)
}


watch(() => defaultLanguage.value, (nval) => {
  if (nval) {
    // if field is required, set rules
    if (attrs?.isRequired) {
      emit('setRules', { requiredMultilang: {
        lang: defaultLanguage.value,
      } })
    }
}
}, { immediate: true })
const pathValue = (name.value || '')
  .split(/\.|\[(\d+)\]/)
  .filter(Boolean)

watch(() => form.value, (nval) => {
  if (!nval || equals(nval, localForm.value)) return 
  localForm.value = path(pathValue, nval || {})
}, { immediate: true, deep: true})


const __returned__ = { attrs, props, emit, store, languages, defaultLanguage, name, form, localForm, setValue, pathValue, computed, ref, watch, toRefs, useAttrs, get path() { return path }, get useUserStore() { return useUserStore }, get equals() { return equals } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})