import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useSlots } from 'vue'
import { useLikesStore } from '@m/store/likes'


export default /*@__PURE__*/_defineComponent({
  __name: 'Counter',
  setup(__props) {

const slots = useSlots()

const likesStore = useLikesStore()

const render = () => slots.default({
  count: likesStore.likesInfo.count || 0,
  isDeterminated: likesStore.likesInfo.isDeterminated,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render))
}
}

})