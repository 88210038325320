import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ckeditor, {
      editor: $setup.ClassicEditor,
      config: $setup.ckeditorConfig,
      "model-value": $setup.val,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.val) = $event))
    }, null, 8, ["editor", "model-value"]),
    _cache[1] || (_cache[1] = _createElementVNode("pre", null, "!", -1)),
    _renderSlot(_ctx.$slots, "label")
  ]))
}