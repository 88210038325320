import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted, watch, toRefs } from 'vue'
import { maps } from '@/plugins/x004-google-maps-loader'
import { PIN_ICON } from '@/const'
import { sendFileResource } from '@/plugins/resource';
import { isEmpty } from 'ramda';

export default /*@__PURE__*/_defineComponent({
  __name: 'CityMapAutocomplete',
  props: {
  points: Array,
  initialCity: Object,
  initialLocation: Object,
  rules: Object,
},
  emits: ['save'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const position = ref({})
const address = ref('')
const country = ref(window.country_code)
let map = null
let markerElement = null
let markers = []
const googleInstance = ref(null)
const autocomplete = ref({})

const props = __props;

const { initialCity, initialLocation } = toRefs(props)

const city = ref(initialCity.value || JSON.parse(window.current_city))

const saveCity = (value: any) => {
  city.value = value
  setCity(value.location)
}

const clearAllMarkers = () => {
  markers.forEach((marker) => {
    marker.setMap(null);
  })
  markers = []
}

const createMarker = (position)  => {
  clearAllMarkers()
  
  const marker = new markerElement({
    map,
    position,
    icon: PIN_ICON,
  });
  markers.push(marker)
}

const setLocationFromAutocomplete = value => {
  const lat = value.geometry.location.lat()
  const lng = value.geometry.location.lng()
  position.value = { lat, lng }
  address.value = value.formatted_address
  setCity(position.value)
  createMarker(position.value)
}
const send = () => {
  const d = {
    location: {
      ...position.value,
      address: address.value,
    },
    city: city.value,
  }
  emit('save', d)
}

const setCity = value => {
  if (!map) return 
  position.value = {
    lat: parseFloat(value.lat),
    lng: parseFloat(value.lng)
  }
  map.setCenter({
    ...position.value,
  })
  position.value = value
  send()
}

const getAddressFromCoords = async (latLng) => {
  const geocoder = new googleInstance.value.Geocoder();

  geocoder.geocode({ location: latLng }, (results, status) => {
    if (status === 'OK' && results[0]) {
      address.value = results[0].formatted_address;
      autocomplete.value = {
        lat: latLng.lat(),
        lng: latLng.lng(),
        address: address.value,
      }
      position.value = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      }
      send()
    } else {
      console.error('Geocoder failed due to:', status);
    }
  });
};


onMounted(async () => {
  await maps().then(async (google) => {
    googleInstance.value = google
    const { Map } = await google.importLibrary("maps") as google.MapsLibrary
    const { Marker } = await google.importLibrary("marker") as google.MarkerLibrary
    let center = ''
    const defaultLocation = city.value?.location
    if (isEmpty(initialLocation.value)) {
      center = initialCity.value?.location
    }
    if (isEmpty(center)) {
      center = initialLocation.value
    }
    map = new Map(document.getElementById('map') as HTMLElement, {
      center: center || defaultLocation,
      zoom: 12,
      streetViewControl: false,
    })

    map.addListener("click", (e: google.maps.MapMouseEvent) => {
      const latLng = e.latLng
      createMarker(latLng)
      getAddressFromCoords(latLng)
    });

    markerElement = Marker
    if (center) {
      createMarker(center)
    }
  })
})

watch(() => initialLocation.value, (value) => {
  if (value) {
    autocomplete.value = value
  }
}, { immediate: true })



const __returned__ = { emit, position, address, country, get map() { return map }, set map(v) { map = v }, get markerElement() { return markerElement }, set markerElement(v) { markerElement = v }, get markers() { return markers }, set markers(v) { markers = v }, googleInstance, autocomplete, props, initialCity, initialLocation, city, saveCity, clearAllMarkers, createMarker, setLocationFromAutocomplete, send, setCity, getAddressFromCoords, ref, onMounted, watch, toRefs, get maps() { return maps }, get PIN_ICON() { return PIN_ICON }, get sendFileResource() { return sendFileResource }, get isEmpty() { return isEmpty } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})