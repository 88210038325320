import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, toRefs } from 'vue'

const p = 'password'
const t = 'text'

const NUMBER_INPUT_TYPE = 'number'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleInput',
  props: {
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    default: '',
  },
  value: {
    default: '',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const { modelValue } = toRefs(props)

const emit = __emit

const inputType = ref(props.type)
const val = ref(modelValue.value)

watch(() => modelValue.value, (nval, oval) => {
  if (oval === nval) return
  val.value = nval
}, { immediate: true })

/* eslint-disable */
const isPasswordField = inputType.value === p

const toggleType = () => inputType.value = inputType.value === p ? t : p

const setValue = e => {
  // FIXME: find number input correct value
  if (e.target.validity.badInput) return
  const v = inputType.value == NUMBER_INPUT_TYPE ? Number(e.target.value) : e.target.value
  emit("update:modelValue", v)
}


const __returned__ = { props, modelValue, emit, p, t, NUMBER_INPUT_TYPE, inputType, val, isPasswordField, toggleType, setValue, ref, watch, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})