import { defineComponent as _defineComponent } from 'vue'
import { toRefs, 
  ref,
  computed,
  useSlots,
} from 'vue'
import type { ComputedRef } from 'vue'

import { useField } from 'vee-validate';

const r = 'required'



export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDescriptor',
  props: {
  component: {
    type: String,
    default: 'd-control-simple-input',
  },
  name: {
    type: String,
    required: true,
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
  isSuccess: Boolean,
  disabled: Boolean,
  inputLabel: String,
  staticLabel: {
    type: Boolean,
    default: false,
  },
  options: Array,
  labelBy: String,
  type: {
    type: String,
    default: 'text',
  },
  rules: {
    type: String, Object,
    default: '',
  },
  languages: {
    type: Array,
    default: null,
  },
  setValue: {},
  inputClass: {},
},
  emits: ['updateModel', 'update:modelValue', 'input', 'clearMultiselect'],
  setup(__props, { expose: __expose, emit: __emit }) {

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

const props = __props


const { isRequired, id, component, staticLabel, rules, name } = toRefs(props)
const updatedRules = ref(rules.value)
// const { meta, value, errorMessage, handleChange, handleBlur } = useField(() =>
//   name.value,
//   updatedRules.value,
//   {
//     validateOnValueUpdate: true,
//   }
// )

const emit = __emit


const inputValue = ref(null)
const isValid = ref(null)
const setRules = (val) => {
  const rules = updatedRules.value
  if (typeof rules == 'string') {
    updatedRules.value = {
      ...rules
        .split('|')
        .reduce((acc, e) => acc[e] = true, {} ),
      ...val
    }
  } else {
    updatedRules.value = { ...rules, ...val }
  }
}

const getFP = f => {
  const t = { ...f, onChange: () => {} }
  return t
}

const resetValue = () => {
  inputValue.value = null
}

const updateValue = val => {
  inputValue.value = val
  // console.log(val);
  emit('updateModel', val)
}
const updateModelValue = val => {
  // console.log(val);
  inputValue.value = val
  // handleBlur(val, true)
  // console.log( meta.value, value.value, errorMessage.value,);
  emit('update:modelValue', val)
}

const eSlots = computed(() => {
  return getESlots([...Object.keys(useSlots())])
})


const getProps = f => {
  return f ? f.field : {}
}

const getLabelClass: ComputedRef<string> = computed(() => staticLabel.value ? "d-control-input__label--static": "d-control-input__label--bound")


__expose({
  resetValue,
})

const __returned__ = { eSlotsResolver, getESlots, props, isRequired, id, component, staticLabel, rules, name, updatedRules, emit, inputValue, r, isValid, setRules, getFP, resetValue, updateValue, updateModelValue, eSlots, getProps, getLabelClass, toRefs, ref, computed, useSlots, get useField() { return useField } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})