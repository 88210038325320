import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted, watch, toRefs } from 'vue'
import { organizationSocialList } from '@/services/general.service'

interface CountryCode {
  service: string
  country: { code2: string }
  regexp?: RegExp
  numberAfterCode: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SocialNetwork',
  props: {
  rules: {},
  modelValue: {},
  disabled: {
    type: Boolean,
    default: false,
  },
  inputLabel: {
    type: String,
  },
},
  emits: ['update:modelValue', 'setRules'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const { modelValue } = toRefs(props)

const emit = __emit

const id = ref('')
const link = ref('')
const service = ref<CountryCode | null>(null)
const list = ref<CountryCode[]>([])

const getList = async () => {
  const { items } = await organizationSocialList.execute()
  list.value = items
  return list
}

const getSocialNetworks = async () => {
  if (list.value.length) return
  await getList()
  if (!service.value) {
    const [first] = list.value
    service.value = first
  }
  return list
}

const save = (val) => {
  setTimeout((_) => {
    emit('update:modelValue', {
      service: service.value,
      link: link.value,
      id: id.value,
    })
  }, 300)
}

getSocialNetworks()

watch(
  () => props.modelValue,
  async (nval, oval) => {
    if (nval === oval) return
    if (typeof nval === 'string') return
    if (nval?.link !== link.value) {
      link.value = nval?.link
    }
    if (nval?.service !== service.value) {
      service.value = nval?.service
    }
    if (nval?.id !== id.value) {
      id.value = nval?.id
    }
  },
  { immediate: true }
)

const __returned__ = { props, modelValue, emit, id, link, service, list, getList, getSocialNetworks, save, ref, onMounted, watch, toRefs, get organizationSocialList() { return organizationSocialList } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})