import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Spread',
  props: {
  spread: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const localSpread = ref({
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
})

const totalSpread = ref({})

const calcSpread = () => {
  Object.keys(props.spread).forEach(k => {
    if (Number(k)) {
      localSpread.value[k] = props.spread[k]
    }
  })

  const total = Object.values(localSpread.value).reduce((acc, value) => acc + value, 0)

  totalSpread.value = Object.keys(localSpread.value).reduce((acc, key) => {
    const percentage = total ? ((localSpread.value[key] / total) * 100).toFixed(0) : 0

    acc[key] = percentage

    return acc
  }, {})
}

onMounted(() => {
  calcSpread()
})

const __returned__ = { props, localSpread, totalSpread, calcSpread, ref, onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})