import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue'
import { debounce } from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectWidget',
  props: {
  modelValue: {},
  filter: Object,
  config: {
    type: Object,
    default: () => ({}),
  },
  resetTrigger: Boolean,
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const control = ref(null)

const model = ref(props.modelValue || null)
const filterLabel = props.config.label || 'value'
const optionTitle = props.config.title || 'title'

const update = (value: any) => {
  if (Array.isArray(value)) {
    const normalizedTo = value.map(el => el[filterLabel])

    emit('update:modelValue', normalizedTo)
  } else if (value) {
    emit('update:modelValue', value[filterLabel])
  } else {
    emit('update:modelValue', null)
  }
}

const debounceUpdate = debounce(update, 100)

const normalizeFrom = (val: any): string[] => {
  if (Array.isArray(val)) {
    return val
  } else {
    return [val]
  }
}

const setValueFromUrl = (val: any) => {
  if (!val) {
    model.value = ''

    return
  }

  const normalizedValue = normalizeFrom(val)
  const items: string[] = props.filter.values

  const checked = items.filter(el => normalizedValue.find(v => (v).toString() === (el[filterLabel]).toString()))

  if (!checked.length) {
    model.value = ''

    return
  }

  if (props.config.multiple) {
    model.value = checked
  } else {
    model.value = checked[0]
  }
}

// const hasValue = computed(() => {
//   if (Array.isArray(model.value) && model.value.length) {
//     return true
//   }

//   if (model.value) {
//     return true
//   }

//   return false
// })

watch(() => props.modelValue, () => {
  setValueFromUrl(props.modelValue)
}, { immediate: true })

watch(() => props.resetTrigger, () => {
  model.value = null

  control.value.resetValue()
})

const __returned__ = { props, emit, control, model, filterLabel, optionTitle, update, debounceUpdate, normalizeFrom, setValueFromUrl, ref, watch, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})