import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-label error-label--size-error"
}

import {
  ref,
  computed,
  watch,
} from 'vue'


import useGlobal from '@/composables/useGlobal'
import { useErrorToast } from '@/composables/useToast'

import Cookies from 'js-cookie';


export default /*@__PURE__*/_defineComponent({
  __name: 'Image',
  props: {
  displayLabel: {
  },
  currentUrl: String,
  modelValue: {},
  field: {
    type: String,
    default: 'field'
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const { $t } = useGlobal()

const sizeError = ref(false)
const sizeErrorMessage = $t('File must not exceed 5 MB')
const loaderDefaultLabel = $t('Add photo')

const props = __props

const emit = __emit
const preview = ref(null)
const url = ref('')
const src = computed(() => {
  const v = props.modelValue

  if (preview.value) return preview.value

  console.log('model', v);
  return (
    !v ? null : (
      typeof v === 'string' || v?.origin
      ? v
      : v[props.field]
    )
  )
})

watch(() => props.currentUrl, () => {
  url.value = props.currentUrl
}, {immediate: true, deep: true})

function onResult(r) {
  preview.value = r.image.src
  sizeError.value = false
  // If it was a @file handler.
  // toBase64(blob).then(base64 => {
  //   preview.value = base64
  // })
}

const getHeaders = () => {
  return [['X-Csrftoken', `${Cookies.get('csrftoken')}`]]
}

function onFile(file) {
  preview.value = file ? window.URL.createObjectURL(file) : null
  sizeError.value = false
  emit(
    'update:modelValue',
    file
  )
}

function onSave(xhr: XMLHttpRequest) {
  preview.value = null
  emit(
    'update:modelValue',
    !xhr ? null : JSON.parse(xhr.responseText).data.item
  )
}

function onError(e) {
  const body = JSON.parse(e.responseText)
  body.errors.forEach(error => {
    if ('request' === error.domain && error.state) {
      console.log(error.state);
      for (const e in error.state) {
        error.state[e].forEach(r => {
          useErrorToast(r.message)
        })
      }
    }
  })
  preview.value = null
  // sizeError.value = true
  // setTimeout(() => {
  //   sizeError.value = false
  // }, 4000);

}


return (_ctx: any,_cache: any) => {
  const _component_up_uploader = _resolveComponent("up-uploader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_up_uploader, _mergeProps({
      url: url.value,
      label: "",
      "placeholder-src": "/static/img/user.svg",
      src: src.value,
      uploadOptions: { method: 'POST', initialHeaders: getHeaders(), field: __props.field },
      onResult: onResult,
      onSave: onSave,
      onFile: onFile,
      onError: onError
    }, _ctx.$attrs), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, sn) => {
        return {
          name: sn,
          fn: _withCtx((sd) => [
            _renderSlot(_ctx.$slots, sn, _normalizeProps(_guardReactiveProps(sd)))
          ])
        }
      })
    ]), 1040, ["url", "src", "uploadOptions"]),
    (sizeError.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(sizeErrorMessage)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})