import { unref as _unref, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fake-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "fake-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.show)
      ? (_openBlock(), _createBlock(_unref($props["tag"]), _mergeProps({
          key: 0,
          class: "fake-tag",
          tabindex: 1,
          ref: "visibleinput",
          onBlur: $setup.blur,
          onClose: $setup.blur,
          tag: $props.tag,
          modelValue: $setup.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event))
        }, [_ctx.$attrs, _ctx.$props], _toHandlers(_ctx.$listeners)), null, 16, ["tag", "modelValue"]))
      : _renderSlot(_ctx.$slots, "dummy-value", {
          key: 1,
          value: $setup.value,
          focus: $setup.focus
        }, () => [
          _createElementVNode("p", {
            class: _normalizeClass(["fake-input", { "is-disabled": $props.disabled }]),
            onFocus: $setup.focus,
            tabindex: 0
          }, _toDisplayString($props.valueFormatter ? $props.valueFormatter($setup.value) : $setup.value), 35),
          (!$setup.value)
            ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString($props.label), 1))
            : _createCommentVNode("", true),
          ($props.showAfter)
            ? _renderSlot(_ctx.$slots, "after", { key: 1 }, () => [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "multiselect__select" }, null, -1))
              ])
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "add")
        ])
  ]))
}